import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Layout from '@/components/layout/index.vue'
import AuthLayout from '@/views/auth/layout.vue'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: Layout,
        redirect: '/channels/me',
        children: [
            {
                path: '/channels/me',
                name: 'me',
                component: () => import('@/views/me/index.vue'),
                meta: {title: 'Midjourney and Niji App'}
            },
            {
                path: '/channels/midjourney',
                name: 'midjourney',
                component: () => import('@/views/channel/midjourney/index.vue'),
                meta: {title: 'Midjourney'}
            },
            {
                path: '/channels/niji',
                name: 'niji',
                component: () => import('@/views/channel/niji/index.vue'),
                meta: {title: 'Niji'}
            },
            {
                path: '/channels/gallery',
                name: 'gallery',
                component: () => import('@/views/gallery/index.vue'),
                meta: {title: '画廊'}
            },
        ]
    },
    {
        path: '/auth',
        name: 'auth',
        component: AuthLayout,
        redirect: '/login',
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('@/views/auth/login/index.vue'),
                meta: { transition: 'bounce', title: 'Login'}
            },
            {
                path: '/register',
                name: 'register',
                component: () => import('@/views/auth/register/index.vue'),
                meta: { transition: 'bounce', title: 'Register'}
            },
        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

import Vue from 'vue';
import { DirectiveOptions } from 'vue/types/options';

interface HTMLElementWithSpinner extends HTMLElement {
    __spinnerWrapper?: HTMLDivElement;
    __spinner?: HTMLDivElement;
    __overlay?: HTMLDivElement;
}

const loadingDirective: DirectiveOptions = {
    bind(el: HTMLElementWithSpinner, binding) {
        const spinnerWrapper = document.createElement('div');
        spinnerWrapper.className = 'img-spinner-wrapper';

        const spinner = document.createElement('div');
        spinner.className = 'img-spinner';

        spinnerWrapper.appendChild(spinner);

        const overlay = document.createElement('div');
        overlay.className = 'img-loading-overlay';
        overlay.appendChild(spinnerWrapper);

        el.style.position = 'relative';
        el.appendChild(overlay);

        el.__spinnerWrapper = spinnerWrapper;
        el.__spinner = spinner;
        el.__overlay = overlay;

        if (!binding.value) {
            overlay.style.display = 'none';
        }
    },
    update(el: HTMLElementWithSpinner, binding) {
        if (binding.value) {
            el.__overlay!.style.display = 'flex';
        } else {
            el.__overlay!.style.display = 'none';
        }
    },
    unbind(el: HTMLElementWithSpinner) {
        el.removeChild(el.__overlay!);
        delete el.__spinnerWrapper;
        delete el.__spinner;
        delete el.__overlay;
    }
};

Vue.directive('img-loading', loadingDirective);

export default loadingDirective;

import { DirectiveOptions } from 'vue';

const SpinnerDirective: DirectiveOptions = {
    bind(el, binding) {
        const spinnerContainer = document.createElement('div');
        spinnerContainer.className = 'spinner-container';

        const spinner = document.createElement('div');
        spinner.className = 'spinner';
        spinnerContainer.appendChild(spinner);

        el.style.position = 'relative';

        if (binding.value) {
            el.appendChild(spinnerContainer);
        }

        (el as any).spinnerContainer = spinnerContainer;
    },
    update(el, binding) {
        const spinnerContainer = (el as any).spinnerContainer;
        if (binding.value) {
            if (!el.contains(spinnerContainer)) {
                el.appendChild(spinnerContainer);
            }
        } else {
            if (el.contains(spinnerContainer)) {
                el.removeChild(spinnerContainer);
            }
        }
    },
    unbind(el) {
        const spinnerContainer = (el as any).spinnerContainer;
        if (el.contains(spinnerContainer)) {
            el.removeChild(spinnerContainer);
        }
    }
};

export default SpinnerDirective;

/**
 * Created by Administrator on 2018/2/9.
 */
// 引用axios
import axios from 'axios'
import { storage } from '@/utils/Storage';
import Router from '@/router/index';

axios.defaults.withCredentials = true;

const baseUrl = process.env.VUE_APP_VITE_GLOB_API_URL
// 自定义判断元素类型JS
function toType(obj) {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}

// 参数过滤函数
function filterNull(o) {
    for (var key in o) {
        if (o[key] === null) {
            delete o[key]
        }
        if (toType(o[key]) === 'string') {
            o[key] = o[key].trim()
        } else if (toType(o[key]) === 'object') {
            o[key] = filterNull(o[key])
        } else if (toType(o[key]) === 'array') {
            o[key] = filterNull(o[key])
        }
    }
    return o;
}


/*
 接口处理函数
 这个函数每个项目都是不一样的，我现在调整的是适用于
 https://cnodejs.org/api/v1 的接口，如果是其他接口
 需要根据接口的参数进行调整。参考说明文档地址：
 https://cnodejs.org/topic/5378720ed6e2d16149fa16bd
 主要是，不同的接口的成功标识和失败提示是不一致的。
 另外，不同的项目的处理方法也是不一致的，这里出错就是简单的alert
 */
const $http = axios.create({
    baseURL: baseUrl,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
})
function apiAxiosFileStream(url, route = '') {
    const token = storage.get('token')
    return $http.get(url,{
        responseType: 'blob',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}
function apiAxiosUseFormData(url,file, param) {
    if (undefined === param) {
        param = new FormData();
        param.append("file", file);
    }

    const token = storage.get('token')
    return new Promise( ((resolve, reject) => {
        $http.post(url, param,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        }).then( res => {
            if (res.status === 200) {
                if (res.data.code === 401) {
                    /*401状态重定向到登录页*/
                    Router.replace('/login')
                } else {
                    resolve(res.data);
                }
            } else {
                reject(res.data);
            }
        }).catch(err => {
            let res = err.response
            if (res && (res.status === 401)) {
                location.reload();
            } else {
                if (err) {
                    reject(err)
                }
            }
        })
    }))
}

function apiAxios(method, url, params, success, failure, route = '') {
    return new Promise((resolve, reject) => {
        if (params) {
            params = filterNull(params)
        }
        const token = storage.get('token')
        $http.request({
            method: method,
            url: url,
            data: method === 'POST' || method === 'PUT' ? params : null,
            params: method === 'GET' || method === 'DELETE' ? params : null,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 401) {
                    /*401状态重定向到登录页*/
                    Router.replace('/login')
                } else {
                    resolve(res.data);
                }
            } else {
                reject(res.data);
            }
        }).catch(err => {
            let res = err.response
            if (res && (res.status === 401)) {
                location.reload();
            } else {
                if (err) {
                    reject(err)
                }
            }

        })
    })

}
function apiAxiosFileStreamPost(url,params, route = '') {
    const token = storage.get('token')
    return $http.post(url,params,{
        responseType: 'blob',
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
}
// 返回在vue模板中的调用接口
export default {
    get:  (url, params, success, failure, route = '') =>  {
        return apiAxios('GET', url, params, success, failure, route);
    },
    post: function (url, params, success, failure, route = '') {
        return apiAxios('POST', url, params, success, failure, route)
    },
    uploadBase64: function (url, file, p) {
        return apiAxiosUseFormData(url,file, p);
    },
    fileStream: (url, route = '') => {
        return apiAxiosFileStream(url, route);
    },
    fileStreamPost: function(url,params, route = ''){
        return apiAxiosFileStreamPost(url,params, route);
    },
    all: axios.all,
    spread: axios.spread,
}

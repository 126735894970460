import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/index.css'

/*导入自定义指令*/
import DLoading from '@/directive/DLoading/spinner'
import '@/directive/DLoadingMini/index'
import '@/directive/ImgLoading/index'
Vue.directive('spinner', DLoading);

// import FastClick from 'fastclick';
//
// FastClick.attach(document.body);
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import 'bootstrap-icons/font/bootstrap-icons.css'
import './permission.js'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import vhCheck from 'vh-check';
vhCheck();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

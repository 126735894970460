import axiosProxy from './axiosProxy';

/*GET请求封装，返回Promise对象*/
export const get = (url, p, route = '') => {
    return axiosProxy.get(url, p);
}
/*POST请求封装，返回Promise对象*/
export const post = (url, p) => {
    return axiosProxy.post(url, p);
}
/*POST请求封装，返回Promise对象*/
export const upload = (url, p) => {
    return axiosProxy.uploadBase64(url, null, p);
}

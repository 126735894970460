<template>
    <div class="discord-box">
        <!--    左侧app栏    -->
        <div class="left-app" :style="{width: `${leftVisible ? 72 : 0}px`}">
            <template v-if="leftVisible">
                <!--      me      -->
                <AppItem :name="appList[0].name" :icon="appList[0].icon" :path="appList[0].path"></AppItem>
                <div style="width: 32px">
                    <Divider margin="0 0 14px"></Divider>
                </div>

                <AppItem v-for="app in appList.slice(1, 3)" :name="app.name" :image="app.image" :path="app.path"></AppItem>
            </template>
        </div>
        <router-view/>
    </div>
</template>

<script>
import AppItem from "@/components/AppItem/index.vue";
import Divider from "@/components/Divider.vue";
/* 组件 */
export default {
    name: 'index',
    data() {
        return {
            appList: [
                {name: 'me', icon: 'bi bi-discord', path: '/channels/me'},
                {name: 'Midjourney', image: require('@/assets/images/midjourney.png'), path: '/channels/midjourney'},
                {name: 'Niji', image: require('@/assets/images/niji.png'), path: '/channels/niji'},
                {name: '画廊', image: require('@/assets/images/gallery.svg'), path: '/channels/gallery'},
            ]
        }
    },
    components: {AppItem, Divider},
    mixins: [],
    filters: {},
    props: {},
    computed: {
        leftVisible() {
            return this.$store.state.showLeftChannelList
        },
        isMobile() {
            return this.$store.state.isMobile;
        }
    },
    watch: {},
    methods: {},
    created() {

    },
    mounted() {

    }
}
</script>

<style scoped lang="scss">
@import "index.scss";
</style>
<template>
    <div style="width: 100%" class="block-box">
        <el-tooltip class="item" effect="dark" :content="name" placement="right" popper-class="custom-tooltip">
            <div class="app-item-box" @click="toChannel">
                <div class="app-item" :class="[isActive ? 'active' : '', image ? 'has-image' : '']">
                    <div class="left-active" v-if="isActive" :style="{left: isMobile ? '-12px' : '-16px'}"></div>
                    <div class="app-item__icon">
                        <i :class="icon" style="font-size: 27px" v-if="!image"></i>
                        <img v-else :src="image" />
                    </div>
                </div>
            </div>

        </el-tooltip>
    </div>
</template>

<script>
/* 组件 */
export default {
    name: 'index',
    data() {
        return {}
    },
    components: {},
    mixins: [],
    filters: {},
    props: {
        name: '',
        path: '',
        icon: '',
        image: '',
    },
    computed: {
        isActive() {
            return this.$route.path === this.path
        },
        isMobile() {
            return this.$store.state.isMobile;
        }
    },
    watch: {},
    methods: {
        toChannel() {
            this.$router.push(this.path)
        }
    },
    created() {

    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>
@import "index.scss";
</style>
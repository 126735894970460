<template>
    <div id="app">
        <router-view/>

        <template v-if="isDev">
            <!--   开发环境显示控制台信息     -->
            <div class="dev-console" v-if="isDev && showConsole" >
                <div class="log-title">
                    <div style="height: fit-content;">控制台</div>
                    <div>
                        <el-button @click="showConsole = !showConsole" type="text">
                            {{showConsole ? '隐藏' : '显示'}}
                        </el-button>
                    </div>
                </div>
                <div class="log-content-box" ref="console">
                    <div class="log" v-for="(log, index) in logs">
                        <div class="log-time">{{log.time}}：</div>
                        <div class="log-content">{{log.content}}</div>
                    </div>
                </div>
            </div>
<!--            <div class="show-console" v-if="!showConsole" @click="showConsole = true">
                显示控制台
            </div>-->
        </template>
    </div>
</template>

<script>
import * as api from "@/views/channel/api";
import * as authApi from '@/views/auth/login/api.js'

export default {
    data() {
        return {
            noticeList: [],
            logs: [],
            showConsole: false
        }
    },
    watch: {
        path(nv, ov) {
            this.watchUserInfo();
        }
    },
    methods: {
        async loadNotice() {
            let {code, data} = await api.getNotice({type: 'system'});
            await this.$store.dispatch('setNotice', data || [])
        },
        async loadUserInfo() {
            let {code, data} = await authApi.userinfo();
            console.log(data)
            await this.$store.dispatch('setUserInfo', data || {})
        },
        watchUserInfo() {
            this.clearAllTimers();
            if (this.$route.path !== '/login') {
                let id = setInterval(() => {
                    authApi.userinfo();
                }, 60000)
                window.myTimers.push(id);
            }
        },
        clearAllTimers() {
            window.myTimers.forEach(id => clearInterval(id));
            window.myTimers = [];
        },
        setDevice() {
            const width = window.screen.width;
            // const height = window.screen.height;
            // const devicePixelRatio = window.devicePixelRatio;

            this.$store.dispatch('setIsMobile', width <= 767);
        },
        doConsole() {
            // 保存原始的console.log方法
            const originalConsoleLog = console.log;
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
            // 重写console.log方法
            console.log = (...args) => {
                const date = new Date();
                const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };

                // 获取格式化的日期
                const formattedDate = date.toLocaleDateString('zh-CN', dateOptions);

                // 获取格式化的时间
                const formattedTime = date.toLocaleTimeString('zh-CN', timeOptions);

                // 拼接日期和时间
                const formattedDateTime = `${formattedDate} ${formattedTime}`;
                // 将参数转换为字符串并处理对象
                const formattedArgs = args.map(arg => {
                    if (typeof arg === 'object') {
                        try {
                            return JSON.stringify(arg, null); // 美化输出JSON
                        } catch (error) {
                            return '[Circular]'; // 处理循环引用的情况
                        }
                    }
                    return String(arg); // 确保所有参数都转换为字符串
                }).join(' ');

                // 将日志信息添加到logs数组中
                this.logs.push({time: formattedTime, content: formattedArgs});
                originalConsoleLog.apply(console, [`%c${formattedDateTime}:`, 'color: red;', ...args]);

                /*if (this.showConsole) {
                    this.$refs.console && this.$refs.console.scrollTo({
                        top: this.$refs.console.scrollHeight,
                        behavior: 'smooth'
                    });
                }*/
            };
        },
        setVh() {
            document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
        }
    },
    computed: {
        isDev() {
            return process.env.NODE_ENV === 'development'
        },
        path() {
            return this.$route.path;
        },
        innerHeight() {
            return window.innerHeight
        }
    },
    beforeRouteUpdate() {
        this.loadNotice();
        this.loadUserInfo();
    },
    mounted() {
        window.myTimers = [];
        this.loadNotice();
        this.loadUserInfo();
        this.setDevice();
        if (this.isDev) {
            // this.doConsole();
        }

        // 监听窗口大小变化，调整 vh
        window.addEventListener('resize', this.setVh);
        this.setVh();

        this.$nextTick(() => {
            this.setVh();
        })
    },
    beforeDestroy() {
        // 组件销毁时，移除窗口大小变化的监听器
        window.removeEventListener('resize', this.setVh);
    }
}

</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    position: relative;
    height: 100%;
}

.dev-console {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 600px;
    z-index: 700;
    background-color: #fff;
    border-radius: 6px;
    padding: 15px;
    box-sizing: border-box;
    color: #000;

}

.log-content-box {
    height: calc(var(--vh, 1vh) - 95px);
    overflow: auto;
}
.log {
    width: 100%;
    text-align: left;
    padding: 3px 0;
    border-bottom: 1px solid #00000024;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    min-height: 30px;
}
.log-time {
    white-space: nowrap;
    line-height: 22px;
    color: #3b88c3;
}

.log-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.log-content {
    white-space: pre-wrap;   /* 保留空格和换行符 */
    word-wrap: break-word;   /* 长单词或URL超出宽度时换行 */
    word-break: break-all;   /* 对长单词强制换行 */
}
.show-console {
    position: absolute;
    bottom: 90px;
    right: 105px;
    background-color: #00000024;
    border-radius: 6px;
    padding: 5px 8px;
    cursor: pointer;
    z-index: 700;
}

@media screen and (max-width: 768px) {
    .dev-console {
        width: calc(100vw - 20px);
    }
    .log-content-box {
        height: calc(var(--vh, 1vh) - 120px);
    }
    .show-console {
        right: 20px;
        bottom: 40px;
    }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        hasDialog: false,
        noticeList: [],
        userinfo: {},
        showLeftChannelList: true,
        isMobile: false,
        oss: {}
    },
    getters: {},
    mutations: {
        SET_DIALOG(state, payload) {
            state.hasDialog = payload
        },
        SET_NOTICE(state, payload) {
            state.noticeList = payload
        },
        SET_USERINFO(state, payload) {
            state.userinfo = payload
        },
        SET_SHOW_LEFT_CHANNEL_LIST(state, payload) {
            state.showLeftChannelList = payload
        },
        SET_IS_MOBILE(state, payload) {
            state.isMobile = payload
        },
        SET_OSS(state, payload) {
            state.oss = payload
        }
    },
    actions: {
        setDialog({commit}, payload) {
            commit('SET_DIALOG', payload)
        },
        setNotice({commit}, payload) {
            commit('SET_NOTICE', payload)
        },
        setUserInfo({commit}, payload) {
            commit('SET_USERINFO', payload)
        },
        setLeftVisible({commit}, payload) {
            commit('SET_SHOW_LEFT_CHANNEL_LIST', payload)
        },
        setIsMobile({commit}, payload) {
            commit('SET_IS_MOBILE', payload)
        },
        setOss({commit}, payload) {
            commit('SET_OSS', payload)
        }
    },
    modules: {}
})

// 默认缓存期限为7天
const DEFAULT_CACHE_TIME = 60 * 60 * 24 * 7;

/**
 * 创建本地缓存对象
 * @param {string=} prefixKey -
 * @param {Object} [storage=localStorage] - sessionStorage | localStorage
 */

export default class Storage {
    private storage: globalThis.Storage;
    private prefixKey?: string;

    constructor(prefixKey = '', storage = localStorage) {
        this.storage = storage;
        this.prefixKey = prefixKey;
    }

    private getKey(key: string) {
        return `${this.prefixKey}${key}`.toUpperCase();
    }

    /**
     * @description 设置缓存
     * @param {string} key 缓存键
     * @param {*} value 缓存值
     * @param expire
     */
    set(key: string, value: any, expire = DEFAULT_CACHE_TIME) {
        const now = new Date()
        const item = {
            value: value,
            expiry: now.getTime() + (expire * 1000),
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    /**
     * 读取缓存
     * @param {string} key 缓存键
     * @param {*=} def 默认值
     */
    get(key: string, def: any = null) {
        const itemStr = localStorage.getItem(key)
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key)
            return null
        }
        return item.value || def
    }

    /**
     * 从缓存删除某项
     * @param {string} key
     */
    remove(key: string) {
        localStorage.removeItem(key);
    }

    /**
     * 清空所有缓存
     * @memberOf Cache
     */
    clear(): void {
        localStorage.clear();
    }
}

export const storage = new Storage('');
